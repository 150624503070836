<template>
  <div>
    <b-alert variant="danger" show>
      <ul class="mb-0">
        <li>발란 최종 카테고리와 네이버 카테고리의 매칭 정보를 조회할 수 있는 화면입니다. 카테고리 매칭의 변경이 필요한 경우 개발팀으로 업데이트 요청해주세요.</li>
        <li>카테고리 매칭 데이터는 매월 1일, 15일 0시 기준으로 업데이트 됩니다.</li>
        <li>전체 데이터를 다운로드하는 경우 부하가 발생할 수 있으니, 반드시 특정 브랜드 또는 카테고리로 검색하여 엑셀 파일을 다운로드 해주시기 바랍니다.</li>
      </ul>
    </b-alert>

    <b-form-group>
      <label for="matching-type">카테고리 매칭 구분</label>
      <b-form-radio-group
        id="matching-type"
        :options="[{ text: '전체', value: null }, { text: '검색 API 호출', value: 'fetch' }, { text: '수동매칭', value: 'fixed' }, { text: '자동매칭', value: 'empty' }]"
        :checked="null"
        v-model="form.matchingType"
        >
      </b-form-radio-group>
    </b-form-group>

    <!-- <b-input-group class="mb-2">
      <b-input-group-prepend>
        <b-button variant="primary" @click="search()" :disabled="busy.search">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="mr-1" small v-if="busy.search"></b-spinner>
        </b-button>
      </b-input-group-prepend>
      <b-form-input id="search" type="text" placeholder="검색어를 입력해주세요"
        v-model="searchStr" @keypress.enter="search()" autocomplete="off" v-focus>
      </b-form-input>
    </b-input-group> -->

    <b-row>
      <b-col>
        <p>
          <brand-preset v-model="form.brand" :hideButtons="false" :hideDisabled="true" :hideOptions="true"></brand-preset>
        </p>
      </b-col>
      <b-col>
        <p>
          <category-preset v-model="form.category" v-bind="{ hideOptions: true, finalCate: 'only' }"></category-preset>
        </p>
      </b-col>
    </b-row>

    <div>
      <b-btn variant="primary" @click="search()">검색 <b-spinner class="mr-1" small v-if="busy.search"></b-spinner></b-btn>
    </div>

    <div class="clearfix">
      <div class="pull-right">
        <span class="mr-2">업데이트: {{ lastUpdateDt }}</span>
        <b-btn variant="success" @click="xlsxDown()">XLSX <b-spinner class="mr-1" small v-if="busy.xlsxDown"></b-spinner></b-btn>
      </div>
    </div>

    <div>
      <v-server-table ref="categoryMappingTable" url="/goods/ep/categoryMatching" :columns="Object.keys(columns)" :options="tableOptions">
      </v-server-table>
    </div>

    <form :action="$api.getHost() + '/goods/ep/categoryMatching/xlsx'" ref="file_form" method="POST" target="file_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j"/>
    </form>
  </div>
</template>

<script>
  import Vue from 'vue';
  // https://matanya.gitbook.io/vue-tables-2
  import { ServerTable, Event } from 'vue-tables-2';

  const COLUMNS = {
    'matchingType': { name: '카테고리 매칭 구분' },
    'brand_nm_kr': { name: '브랜드명(한글)' },
    'categoryCode': { name: '발란 카테고리 코드' },
    'balaanCategoryName': { name: '발란 카테고리 명' },
    'balaanCategoryPath': { name: '발란 카테고리 경로' },
    'naverCategoryId': { name: '네이버 카테고리 코드' },
    'naverCategoryName': { name: '네이버 카테고리 경로' },
  };

  Vue.use(ServerTable, {}, false, 'bootstrap4');

  export default {
    name: 'EpCategoryMatching',
    title: 'EP 카테고리 매핑',
    components: { ServerTable, Event },
    data() {
      return {
        columns: COLUMNS,

        busy: { search: false, list: false },
        hasMore: { list: false },

        perPage: 10,

        form: { brand: [], category: [], matchingType: null },
        searchStr: '',

        mappings: [],
        mappingsFiltered: [],

        tableTheme: 'bootstrap4',
        tableOptions: {
          headings: Object.fromEntries(
            Object.entries(COLUMNS).map(([key, value]) => [key, value.name]),
          ),
          sortable: Object.keys(COLUMNS),
          filterable: false,
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          alwaysShowPerPageSelect: true,
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'scroll',
          },

          requestFunction: (() => {
            const setBusyStatus = (state) => this.busy.search = state;

            const setLastUpdateDt = (i) => this.lastUpdateDt = i;

            const getFormValues = () => {
              const balaanCategoryCodes = this.form.category.map(i => i.value);
              const brandNos = this.form.brand.map(i => i.value);
              const matchingType = this.form.matchingType;

              return {
                balaanCategoryCodes,
                brandNos,
                matchingType,
              };
            };

            return async function(data) {
              const { balaanCategoryCodes, brandNos, matchingType } = getFormValues();
              setBusyStatus(true);

              const qs = new URLSearchParams({ ...data, balaanCategoryCodes, brandNos, ...(matchingType && { matchingType }) }).toString();
              const resp = await this.$api.getJson(`${this.url}?${qs}`);

              setBusyStatus(false);

              setLastUpdateDt(resp.lastUpdateDt);

              return resp;
            };
          })(),
        },

        lastUpdateDt: '',
      };
    },
    mounted() {
    },
    methods: {
      async fetchData() {
        const balaanCategoryCodes = this.form.category ? this.form.category.map(e => e.value) : [];
 
        const resp = await this.$api.postJson('/goods/ep/categoryMatching/list', {
          balaanCategoryCodes,
          onlyFinal: true,
        });
        const { ok, list, lastUpdateDt } = resp;

        if (!ok) {
          console.error(resp);
          return;
        }

        this.mappings = list;
        this.lastUpdateDt = lastUpdateDt;
      },
 
      async search() {
        this.$refs.categoryMappingTable.refresh();
      },

      async xlsxDown() {
        this.$refs.json_data.value = JSON.stringify({
          balaanCategoryCodes: this.form.category.map(i => i.value),
          brandNos: this.form.brand.map(i => i.value),
          matchingType: this.form.matchingType,
        });
        this.$refs.file_form.submit();
      },
    },
  };
</script>
